<template>
  <div class="main-monitor">
    <div class="monitor-map" ref="monitorMapRef"></div>
    <div class="add-prj" :class="adding ? 'blueContainer' : ''" @click="addPrj">新建<br />项目</div>
    <div class="addPrj-steps" v-show="showStep">
      <button class="close-btn" @click="closeSteps">×</button>
      <h3 class="addPrj-title">操作步骤：</h3>
      <ol class="addPrj-list">
        <li>在地图中找到项目位置单击鼠标左键</li>
        <li>然后自动打开新建项目属性对话框，设置项目信息</li>
      </ol>
    </div>
  </div>
</template>

<script setup lang="ts">
import AMapLoader from '@amap/amap-jsapi-loader'
import ApiKeys from '@/config/ApiKeys.json'
import { ref, onMounted, watch, createVNode, render, createApp } from 'vue'
import { getPrjInfo } from '../service/PrjInfo'
import HomePrjInfo from '@/components/mapWindow/HomePrjInfo.vue'
import AddProjectInfo from '@/components/mapWindow/AddProjectInfo.vue'

const monitorMapRef = ref(null)
const prjList = ref<any>([])

// 定义类型
type MaybeRef<T> = T | null

let map: MaybeRef<any> = null
let infoWindow: MaybeRef<any> = null
let AMapInstance: MaybeRef<any> = null
const adding = ref<boolean>(false)
const showStep = ref<boolean>(false)
let marker: MaybeRef<any> = null
// 新增项目
const newPrj = (e: any) => {
  var position = e.lnglat
  if (marker) {
    map.remove(marker)
    // marker.remove()
  }
  const markerIcon = new AMapInstance.Icon({
    size: new AMapInstance.Size(45, 45),
    image: 'https://image.surveyexcel.com/1200w_900h_50q_dfc87a13-fe85-4173-8eb5-4df98f2028bb',
    imageSize: new AMapInstance.Size(45, 45)
  })
  marker = new AMapInstance.Marker({
    position: position,
    map: map,
    icon: markerIcon,
    offset: new AMapInstance.Pixel(-22, -42)
  })
  if (!infoWindow) {
    infoWindow = new AMapInstance.InfoWindow({
      isCustom: true,
      offset: new AMapInstance.Pixel(0, -50)
    })
  }

  const deletePrj = (id: number) => { 
    prjList.value = prjList.value.filter((item: any) => item.id !== id)
  }

  const closeInfoWindow = () => {
    if (infoWindow) {
      infoWindow.close()
      map.remove(marker)
      // marker.remove()
    }
  }
  const addedPrj = (data: any) => {
    infoWindow.close()
    map.off('click', newPrj)
    prjList.value.push(data)
    adding.value = false

    marker.on('click', function () {
      if (!infoWindow) {
        infoWindow = new AMap.InfoWindow({
          isCustom: true,
          offset: new AMap.Pixel(0, -50)
        })
      }
      // 创建Vue组件实例
      const tempDiv = document.createElement('div')
      const app = createApp(HomePrjInfo, { prjInfo: data, deletePrj: deletePrj })
      // 挂载Vue应用到临时DOM
      app.mount(tempDiv)
      // 设置内容并打开窗体
      infoWindow.setContent(tempDiv as HTMLElement)
      infoWindow.open(map, marker.getPosition())
    })
  }

  // 创建Vue组件实例
  const tempDiv = document.createElement('div')
  const app = createApp(AddProjectInfo, {
    position: position,
    closeInfoWindow: closeInfoWindow,
    addedPrj: addedPrj
  })
  // 挂载Vue应用到临时DOM
  app.mount(tempDiv)
  // 设置内容并打开窗体
  infoWindow.setContent(tempDiv as HTMLElement)
  infoWindow.open(map, marker.getPosition())
}
const addPrj = () => {
  if (adding.value) {
    adding.value = false
    showStep.value = false
    map.off('click', newPrj)
  } else {
    map.on('click', newPrj)
    adding.value = true
    showStep.value = true
  }
}

const closeSteps = () => {
  showStep.value = false
}

const renderMap = () => {
  AMapLoader.load({
    key: ApiKeys.apiKey,
    version: '2.0'
  }).then(async (AMap: any) => {
    AMapInstance = AMap
    map = new AMap.Map(monitorMapRef.value, {
      viewMode: '2D', // 设置为3D视图模式
      center: [121.47, 31.23],
      zoom: 12
      // mapStyle: "amap://styles/macaron"
    })
    prjList.value.forEach((item: any, index: number) => {
      const markerIcon = new AMap.Icon({
        size: new AMap.Size(45, 45),
        image: 'https://image.surveyexcel.com/1200w_900h_50q_dfc87a13-fe85-4173-8eb5-4df98f2028bb',
        imageSize: new AMap.Size(45, 45)
      })
      const statusMarker = new AMap.Marker({
        position: [item.lng, item.lat],
        map: map,
        icon: markerIcon,
        offset: new AMap.Pixel(-22, -42)
      })
      statusMarker.on('click', function () {
        if (!infoWindow) {
          infoWindow = new AMap.InfoWindow({
            isCustom: true,
            offset: new AMap.Pixel(0, -50)
          })
        }
        // 创建Vue组件实例
        const tempDiv = document.createElement('div')
        const app = createApp(HomePrjInfo, { prjInfo: item })
        // 挂载Vue应用到临时DOM
        app.mount(tempDiv)
        // 设置内容并打开窗体
        infoWindow.setContent(tempDiv as HTMLElement)
        infoWindow.open(map, statusMarker.getPosition())
      })
      // 监听地图点击事件，关闭信息窗体
      map.on('click', (e: any) => {
        if (infoWindow) {
          infoWindow.close()
        }
      })
    })
  })
}

watch(prjList, async (newValue, oldValue) => {
  renderMap()
})

onMounted(async () => {
  prjList.value = await getPrjInfo()
  console.log(12341, prjList.value)
  renderMap()
})
</script>

<style scoped lang="scss">
.main-monitor {
  width: 100vw;
  height: calc(100vh - 10px);
  .monitor-map {
    width: 100%;
    height: 100%;
  }
  .add-prj {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 90px;
    right: 90px;
    // z-index: 100;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    font-size: 14px;
    line-height: 16px;
    border: 1px solid #ccc;
    vertical-align: middle;
  }
  .addPrj-text {
    width: 300px;
    height: 100px;
    background-color: #fff;
    border-radius: 5px;
  }
  .addPrj-steps {
    position: absolute;
    top: 90px;
    right: 155px;
    background: #f7f7f7;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      border: none;
      background: transparent;
      cursor: pointer;
      font-size: 24px;
      line-height: 1;
      color: #aaa;

      &:hover {
        color: #777;
      }
    }

    .addPrj-title {
      font-size: 18px;
      color: #333;
      margin-bottom: 10px;
    }

    .addPrj-list {
      list-style-type: none;
      counter-reset: step-counter;
      padding-left: 0;

      li {
        counter-increment: step-counter;
        font-size: 16px;
        line-height: 1.5;
        position: relative;
        padding-left: 30px;
        margin-bottom: 5px;

        &::before {
          content: counter(step-counter);
          position: absolute;
          left: 0;
          top: 0;
          background-color: #508ef5;
          color: white;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          text-align: center;
          line-height: 20px;
          font-weight: bold;
        }
      }
    }
  }

  .blueContainer {
    background-color: rgb(66, 192, 243);
  }
}
</style>
