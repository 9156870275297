import axios from 'axios';
import type { ITag, IBaseStation } from '@/models/prjInfo';
export const getPrjInfo = async () => {
    try {
        const response = await axios.get('/api/getAllPrj')
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
}

export const updatePrjInfo = async ( data: any) => {
    try {
        return await axios.post(`/api/newPrj`, {
            data
        })
    } catch (error) {
        console.error(error)
    }
}

export const uploadFiles = async (data: any) => {
    try {
        return axios.post('/upload/uploadfile', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
    } catch {
        console.log('上传失败');
    }
}

export const uploadDwg = async (data: any) => {
    try {
        return axios.post('/upload/mxcad/upfile', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
    } catch (error) {
        console.log(error);
    }
}

export const savePath = async (data: any) => {
  return axios.post('/api/savePath', {data})
}

export const delPrj = async (id: number) => {
  return axios.delete(`/api/delete?id=${id}`)
}

export const delFile = async (id: number) => {
  return axios.delete(`/api/deleteFile?id=${id}`)
}
