<template>
  <div class="info-window-content">
    <a class="title" @click="toPrj(prjInfo.id)">{{ prjInfo.name }}</a>
    <div class="alert-info">
      <p class="alert-item">
        <span class="name">项目经理:</span> <span class="value">{{ prjInfo.manager }}</span>
      </p>
      <p class="alert-item">
        <span class="name">项目编号：</span><span class="value">{{ prjInfo.number }}</span>
      </p>
      <p class="alert-item">
        <span class="name">项目描述：</span><span class="value">{{ prjInfo.description }}</span>
      </p>
      <!-- 添加删除按钮 -->
    </div>
    <el-button
      type="danger"
      class="delete-btn"
      size="mini"
      @click="confirmDelete(prjInfo.id)"
      :icon="Delete"
      circle
      >删除项目</el-button
    >
    <div class="info-window-arrow"></div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, inject, computed } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { Delete } from '@element-plus/icons-vue'
import { delPrj } from '@/service/PrjInfo'

const router: any = inject('router')
const props = defineProps({
  prjInfo: Object,
  deletePrj: Function
})

// 跳转到项目详情页面
const toPrj = (id: number) => {
  window.location.href = `/detail/${id}`
}

// 确认删除项目
const confirmDelete = (id: number) => {
  console.log(id, 1111)

  ElMessageBox.confirm('此操作将永久删除该项目, 是否继续?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      deleteProject(id) // 调用删除方法
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: '已取消删除'
      })
    })
}

// 删除项目
const deleteProject = async (id: number) => {
  try {
    delPrj(id).then((res) => {
      if (props.deletePrj) {
        props.deletePrj(id)
      }
      ElMessage({
        type: 'success',
        message: `项目已成功删除`
      })
    })
  } catch (error) {
    ElMessage({
      type: 'error',
      message: '删除项目失败，请重试'
    })
  }
}
</script>

<style lang="scss" scoped>
.info-window-content {
  position: relative;
  background-color: white;
  width: 20vw;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  .title {
    font-size: 16px;
    color: #508ef5;
    &:hover {
      text-decoration: underline;
    }
  }
  .delete-btn {
    display: flex;
    justify-content: flex-end;
    padding-right: 5px;
    cursor: pointer;
    font-size: 14px;
    &:hover {
      color: red;
      text-decoration: underline;
    }
  }
  .alert-info {
    margin-top: 10px;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    .alert-item {
      margin-bottom: 10px;
      padding: 0 0 10px 10px;
      border-bottom: 1px solid #ccc;
      font-size: 14px;
      color: #666;
      display: flex;
      .name {
        display: inline-block;
        width: 100px;
      }
      .value {
        display: inline-block;
        text-align: center;
        flex: 1;
        width: 8vw;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  .info-window-arrow {
    position: absolute;
    left: 50%;
    bottom: -20px;
    width: 0;
    height: 0;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: white transparent transparent transparent;
    filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.2));
  }
}
</style>
