import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DetailView from '@/views/DetailView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/detail/:id',
      name: 'detail',
      component: DetailView,
      redirect(to) {
        return {
          name: 'Photo',
          params: { id: to.params.id }
        }
      },
      children: [
        {
          path: 'photo',
          name: 'Photo',
          component: () => import('@/components/detail/PhotoView.vue')
        },
        {
          path: 'document',
          name: 'Document',
          component: () => import('@/components/detail/Document.vue')
        },
        {
          path: 'dwg',
          name: 'Dwg',
          component: () => import('@/components/detail/Dwg.vue')
        }
      ]
    }
  ]
})

export default router



// const express = require('express')
// const multer = require('multer')
// const path = require('path')
// const app = express()

// // 配置 Multer
// const storage = multer.diskStorage({
//   destination: function (req, file, cb) {
//     let folder = 'uploads/'
//     if (file.mimetype.startsWith('image')) {
//       folder += 'images/'
//     } else if (file.mimetype === 'application/pdf' || file.mimetype === 'text/plain') {
//       folder += 'documents/'
//     } else if (file.mimetype === 'application/vnd.dwg') {
//       folder += 'cad_files/'
//     }
//     cb(null, folder)
//   },
//   filename: function (req, file, cb) {
//     cb(null, Date.now() + path.extname(file.originalname))
//   }
// })

// const upload = multer({ storage: storage })

// // 上传文件路由
// app.post('/upload', upload.single('file'), (req, res) => {
//   const file = req.file
//   // 在数据库中插入文件元数据
//   const query =
//     'INSERT INTO files (file_name, file_type, file_path, description) VALUES ($1, $2, $3, $4)'
//   const values = [file.filename, file.mimetype, file.path, req.body.description]
//   client.query(query, values, (err, result) => {
//     if (err) {
//       console.error(err)
//       res.status(500).send('Error saving file metadata')
//     } else {
//       res.send('File uploaded and metadata saved')
//     }
//   })
// })

// app.listen(3000, () => {
//   console.log('Server started on port 3000')
// })

